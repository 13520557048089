import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'




const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`



function RoutersFirewallsPage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Routers, Wireless Access Points and  Networks Technology"
        description="WYN Technologies have the best router and wireless access point designing, configuring, and installing technology. Get your Business internet Setup done with us for the best Results."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Routers, Firewalls, Switches, & Wireless Access Points</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Routers, Firewalls, Switches, & Wireless Access Points</GroceryTitle>
              <p>These days, having a reliable, convenient data and internet network in place for your business or workplace has become a real necessity. Your employees’ workstations need wired access points, and your customers & clients expect there to be available access points for easy WiFi access. A network of this nature which combines wired access with multiple WiFi access points is both convenient and practical. And the even better news is that it can be cost-effective too, and can also include maximum security protocols. When it comes to designing, configuring, and installing business networks and network access points, nobody does it better than <strong>WYN Technologies</strong>!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Provide With Our AP Installation & Wi-Fi Heat Mapping Services</GroceryIndustryTitle>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Sales, Installation, & Product Orientation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p><strong>WYN</strong> is proud to be a preferred provider of quality wireless access point products from top brands, including Cisco, TP-Link, D-Link, TRENDnet, Zyxel, and more. Plus, we’re not just a vendor; we install everything we sell, and our clients agree that our installation workmanship is second-to-none. And after the installation is complete, we won’t leave you guessing about how to use your equipment. We’ll also take the time to offer proper product orientation, and will guide you through the whole operations process. In addition, we’ll be patient in answering any questions you may have, as well.</p>
               {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Wi-Fi Heat Mapping & Surveys</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>As more and more businesses move towards meeting their need for wireless network solutions, the competition for finding usable and reliable airspace that doesn’t conflict with neighboring Wi-Fi networks can sometimes pose a difficult challenge. Thankfully, <strong>WYN</strong> provides a sure solution through our Wi-Fi heat map services. We use state-of-the-art hardware and heatmapper software to conduct our analysis, and we’ll deliver a detailed review of your current coverage and how your network may be affected by any nearby interference. We can then help you make any needed adjustments to your wireless infrastructure to best maximize your wireless communication network for the benefit of your business.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle> */}
              {/* <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
              <MarkdownContent>
                <h2>Learn the Terminology – What are Routers, Switches, & Firewalls?</h2>
                <p>You’re probably familiar with wireless access points on at least an elementary level, because chances are good that you make use of this technology every day, in some form or fashion. But do you understand the difference between a router, a switch, and a firewall? Let’s break it down in simple terms:</p>

                <p>A <strong>router</strong> is a type of hardware device that serves the basic function of routing data packets between networks. Routers are typically used to connect a local area network (LAN) to a wide-area network (WAN), but they can also be used to segment larger LANs.</p>

                <p>A <strong>switch</strong> is a hardware device which works at the data link layer of the Ethernet protocol. The switch keeps track of what MAC addresses have been seen on which switch port, and uses this information to govern where it sends future transmissions. In essence, a switch is a high-speed multiport bridge of sorts.</p>
                <p>A <strong>firewall</strong> is a means of protecting secure networks from less secure networks. More specifically, firewalls are used to protect your private network from the Internet as a whole. Most firewalls keep track of the different transmission control protocol (TCP) states to help any unwanted traffic from gaining access to your LAN.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>How Do These Items Connect and Work Together in a Wireless Network?</h2>
                <p>While not everyone can define router in specific terms, most everyone is familiar with modern wireless routers. At home, your wireless router connects to your internet modem and provides you with WiFi coverage for your various wireless internet devices. Most of these types of routers are actually all-in-one units which combine the router with a network switch and built-in firewall security. Commercial routers function in much the same way, but larger enterprises may separate out each function into its own dedicated hardware device. In addition, while most homes only need one internet access point, a business may require several, and may even need multiple outdoor access points. And a network with this kind of scope and complexity should really be designed and set up by a professional, like <strong>WYN Technologies</strong>!</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Best Wireless Access Point Approaches for Businesses</h2>
                <p>Wireless access point (or wireless AP) setup isn’t something to take lightly if you’re a modern business. But just knowing that you need WiFi doesn’t necessarily mean that you know the best way to make it happen for your business, either! When it comes to choosing the right wireless access points and deciding where to place them, there are a couple of important things you should keep in mind.</p>
                <ul>
                  <li>Access points come with a variety of radio and bandwidth options that help determine overall performance. You’ll want to consider how many users will need simultaneous access, along with what your target connection speed should be. .</li>
                  <li>Think about your physical space, too. Are there any interior walls that could hamper signal strength? Where and how should you mount devices indoors and/or outdoors? And what type of structured cabling will be needed to connect and power up your hardware devices? Some smaller scenarios may only require one access point, but oftentimes multiple APs will be needed to ensure seamless connectivity.
                  </li>
                  <li>What security features and management features are valuable and/or useful for your particular needs? Many products come with their own management platform which can be configured to suit your needs and preferences. But the built-in security offerings can vary widely by device and manufacturer.</li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Various Types of Wireless Access Points (APs)</h2>
                <p>When it comes to wireless access points for business, there’s more variety and specificity in terms of applications than ever before. Let’s break down some of the options by various categories:</p>
                <ul>
                  <li>
                    <strong>By application</strong>
                    <p>Commercial APs are suitable for most commercial environments, including stores, restaurants, supermarkets, hotels, etc. There are also enterprise-level APs which are designed for environments which may involve large volumes of users, and may also have more stringent security requirements. Other enterprises may be fine with using standard commercial APs.</p>


                  </li>
                  <li>
                    <strong>By installation method</strong>
                    <p>In-wall APs are also known as panel-type APs. These are actually embedded into the wall, and offer a more compact appearance. The downside of in-wall APs is that transmission rates can sometimes be impacted by the in-wall placement. Ceiling APs can be directly attached to ceilings or walls without having to be embedded. They stand out a bit more than in-wall APs, but can usually be placed in a tasteful way. Ceiling APs usually offer more reliable performance value, too.</p>
                  </li>
                  <li>
                    <strong>By frequency band</strong>
                    <p>Most APs are either single-frequency or dual-band. Single-frequency APs support 2.4G, with transmission rates between 150Mbps and 450Mbps. Single-frequency APs are more susceptible to interference issues with other nearby networks, however. Dual-band APs support both 2.4G and 5G, so they’re better designed for being able to find an optimal transmission frequency with less opportunity for interference.</p>
                  </li>
                  <li>
                    <strong>By controller</strong>
                    <p>Most APs used for home and small network environments are all-in-one devices, meaning they handle everything from routing, to authentication, to firewall security. For commercial and other high-demand environments, standalone APs may be implemented with greater success. Things like authentication and switching are handled separately, so that the AP itself is only concerned with wireless signal transfer.</p>
                  </li>
                  <li>
                    <strong>By network standards</strong>
                    <p>Different APs are built around different protocol standards (ie IEEE 802.11n, 802.11ac, 802.11ax) and reflect different WiFi generations (ie 4, 5, 6, 6E). Each generation of WiFi is based upon a different assortment of frequency bands (ie 2.4G, 5G, 6G), as well. For this reason, it’s also important for you to be aware of what network standards your particular connected devices may demand, if any.</p>
                  </li>
                  <li>
                    <strong>By environment</strong>
                    <p>If you’re only installing APs indoors, then you’re probably not too concerned about environment. However, if you’re installing any outdoor APs, then you’ll also need to make sure to consider your equipment’s ability to withstand temperature extremes, precipitation, humidity, wind, and other weather extremes. Outdoor APs also usually need to be able to deliver a wider signal range, as well.</p>
                  </li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Trust WYN to Deliver the Access Point Solutions You Need!</h2>
                <p><strong>WYN</strong> is a preferred provider and installer of quality access point products from top manufacturers like Cisco, TP-Link, D-Link, TRENDnet, Zyxel, and more. Plus, we do more than just sell and install equipment. We’re happy to help with network design and optimization for your business or organization, too.</p>
                <p>One way we can help to maximize your network performance is through our Wi-Fi heat mapping services. We can provide a detailed review of your current coverage, and we can uncover any potential issues you may be experiencing as a result of any dead spots or potential nearby interference. With that information in hand, we can then partner with you make any needed adjustments or tweaks to your wireless infrastructure so you can truly get the most out of it, all for the ultimate benefit of your business.</p>
                <p>Got questions? Ready to get started? Give us a call at <a href="tel:3368990555">(336) 899-0555</a> today, and let WYN help you take full advantage of your wireless network through finding the best access point solutions!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default RoutersFirewallsPage
